var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-addresses generic-list scroll-container" },
    [
      _c("CategoryTitle", { attrs: { category: _vm.category } }),
      _c(
        "v-list",
        { staticClass: "mt-5" },
        _vm._l(_vm.userAddresses.addresses, function(address) {
          return _c(
            "v-list-item",
            { key: address.addressId, staticClass: "white" },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", { attrs: { cols: "4", sm: "3" } }, [
                    _c("strong", { staticClass: "secondary--text" }, [
                      _vm._v(_vm._s(address.addressName))
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "5" } }, [
                    _c("span", { staticClass: "secondary--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(address.address1) +
                          " " +
                          _vm._s(address.addressNumber) +
                          " - " +
                          _vm._s(address.city) +
                          " (" +
                          _vm._s(address.province) +
                          ")"
                      )
                    ])
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-end", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "main-button mr-2",
                                            attrs: {
                                              large: "",
                                              icon: "",
                                              to: {
                                                name: "EditAddress",
                                                params: {
                                                  addressId: address.addressId
                                                }
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { medium: "" } },
                                          [_vm._v("$edit")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Modifica indirizzo")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "secondary-icon-button",
                                            attrs: { large: "", icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeAddress(
                                                  address.addressId
                                                )
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("$delete")])],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Elimina indirizzo")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "d-flex justify-space-between footer-btn pl-0",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                large: "",
                depressed: "",
                to: { name: "EditAddress" }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("addresses.addAddressButton")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }